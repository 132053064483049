import { get, toString, toNumber, replace } from 'lodash';
import {realizationItemAdapter} from '@/store/realizations/utils';

/**
 * Выборка для перечислений (словарей)
 * @param item
 * @returns {{code: string, name: string, description: string, text: string, value: string}}
 */
export const enumerationAdapter = (item) => ({
  text: toString(item.name),
  id: toString(item.id),
  code: toString(item.code),
  name: toString(item.name),
  description: toString(item.description),
  value: toString(item.code) || toString(item.id),
  approval: get(item, 'approval', true),
  ufActive: get(item, 'ufActive', false),
});

/**
 * Выборка для перечислений (словарей) с value = id
 * @param item
 * @returns {{code: string, name: string, description: string, text: string, value: string}}
 */
export const enumerationIdsAdapter = (item) => ({
  text: toString(item.name),
  id: toString(item.id),
  code: toString(item.code),
  name: toString(item.name),
  description: toString(item.description),
  value:  toString(item.id),
  approval: get(item, 'approval', true),
  ufActive: get(item, 'ufActive', false),
});

/**
 * Выборка для перечислений доп ЦКГ
 * @param item
 * @returns {{code: string, name: string, description: string, text: string, value: string, approval: boolean, ufActive: boolean, value: number}}
 */
export const enumerationCKGAdapter = (item) => ({
  text: toString(item.name),
  id: toString(item.id),
  code: toString(item.code),
  name: toString(item.name),
  value: toString(item.code) || toString(item.id),
  approval: get(item, 'approval', true),
  ufActive: get(item, 'ufActive', false),
  ufClassification: get(item, 'ufClassification', 0),
});

/**
 * Выборка для скидок
 * @param item
 * @returns {{code: string, name: string, id: string, value: string, status: {code: string, name: string}}}
 */
export const saleAdapter = (item) => ({
  id: toString(get(item, 'id')),
  code: toString(get(item, 'saleCode')),
  name: toString(get(item, 'saleName')),
  value: toString(get(item, 'percent')),
  status: {
    code: toString(get(item, 'statusCode')),
    name: toString(get(item, 'statusName')),
  },
});

/**
 * Выборка для истории
 * @param item
 * @returns {{date: string, field: string, from: string, id: string, to: string, user: string}}
 */
export const historyAdapter = (item) => ({
  id: toString(get(item, 'id')),
  field: toString(get(item, 'field')),
  from: toString(get(item, 'old')),
  to: toString(get(item, 'new')),
  user: toString(get(item, 'user')),
  date: toString(get(item, 'date')),
});

/**
 * Выборка клиента
 * @param item
 * @returns {{name: string, inn: string, id: string}}
 */
export const clientAdapter = (item) => {
  return {
    id: toString(get(item, 'id')),
    name: toString(get(item, 'name')),
    inn: toString(get(item, 'code')),
    blocked: get(item, 'blocked', false),
    realizations: realizationItemAdapter(get(item, 'realization')),
  }
};

/**
 * Выборка дистрибьютора
 * @param item
 * @returns {{name: string, id: string}}
 */
export const distributorAdapter = (item) => ({
  id: toString(get(item, 'id')),
  name: toString(get(item, 'name')),
});

/**
 * Выборка для комментария
 * @param item
 * @returns {{date: string, author: string, id: string, text: string, user: string}}
 */
export const commentAdapter = (item) => ({
  id: toString(get(item, 'id')),
  text: toString(get(item, 'detailText')),
  date: toString(get(item, 'dateCreate')),
  author: toString(get(item, 'userName')),
  user: toString(get(item, 'displayProperties.userId.value')),
});

export const fileAdapter = (item) => ({
  id: get(item, 'id',''),
  name: get(item, 'originalName',''),
  size: get(item, 'fileSize','') + ' Байт',
  byte: get(item, 'fileSize',''),
  path: get(item, 'src',''),
  description: get(item, 'description',''),
  extension: get(item, 'extension', ''),
})

export const priceAdapter = (value) => {
  let number = value;
  if(typeof number === 'string') {
    number = toNumber(replace(value, /[^\d-.]/ig, ''))
  }
  return toString(new Intl.NumberFormat().format(number));
};

export const bitrixBooleanList = [
  {value: 'Y', text: 'Да', ufActive: '1'},
  {value: 'N', text: 'Нет', ufActive: '1'}
];
