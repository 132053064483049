import Vue from 'vue';
import Vuex from 'vuex';
import { get } from 'lodash';

import ROLES from '@/api/enums/roles';
import user from './user';
import clients from './clients';
import tasks from './tasks';
import audit from './audit';
import affiliation from './affiliation';
import realizations from './realizations';
import realizationsCislink from './realizationsCislink';
import cislink from './cislink';
import statuses from './statuses';
import classifications from './classifications';
import advance from './advance';
import violationsList from './violationsList';
import checksList from './checksList';
import employee from './employee';
import accounting from './accounting';
import promotions from "./promotions";
import downloads from "./downloads";
import realizationsTender from "@/store/realizationsTender";

Vue.use(Vuex);

export const store = new Vuex.Store({
  modules: {
    user,
    tasks,
    audit,
    clients,
    cislink,
    statuses,
    affiliation,
    realizations,
    realizationsCislink,
    realizationsTender,
    classifications,
    advance,
    violationsList,
    checksList,
    employee,
    promotions,
    accounting,
    downloads
    // Не явно подключен модуль route === $route
  },
  state: {},
  getters: {
    /**
     * Менеджер контроля доступа.
     * @param state
     * @param getters
     * @returns {function(*=): boolean}
     */
    access(state, getters) {
      return func => func(ROLES, get(getters, 'user/role.code'));
    }
  },
  actions: {
    init({ dispatch }) {
      dispatch('clients/fetchSales', null, { root: true });
      dispatch('clients/fetchRegions', null, { root: true });
      dispatch('clients/fetchDistricts', null, { root: true });
      dispatch('clients/fetchClassifications', null, { root: true });
      dispatch('clients/fetchCKGList', null, { root: true });
      dispatch('clients/fetchStatuses', null, { root: true });
      dispatch('clients/fetchBPConnectedList', null, { root: true });
      dispatch('user/getParams', null, { root: true });
    }
  }
});
