<template>
  <div :class="$style.root">
    <v-data-table
        v-if="items"
        dense
        must-sort
        item-key="id"
        :loading="pending"
        loading-text="Загрузка..."
        :class="$style.table"
        :items-per-page="5"
        :hide-default-footer="items.length < 6"
        :items="items"
        :headers="[
        {value: 'period', text: 'Период', cls: 'nowrap'},
        {value: 'sum', text: 'Сумма, руб'},
      ]"
        :item-class="() => [$style.tr, $style.two]"
        :footer-props="{
        showFirstLastPage: true,
        itemsPerPageOptions: [5, 10, 20, -1],
        itemsPerPageText: '',
      }"
    >
      <template v-slot:footer>
        <slot />
      </template>
    </v-data-table>
    <div v-if="pending" :class="$style.loader" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { includes, toString } from 'lodash';
export default {
  name: 'RealizationTenderTable',
  props: {
    inn: { type: String, required: true },
    distributor: { type: String },
  },
  data() {
    return {
      editing: false,
    };
  },
  computed: {
    ...mapGetters({
      pending: 'realizationsTender/pending',
      items: 'realizationsTender/items',
    }),
    watchData() {
      return this.distributor + this.inn;
    }
  },
  methods: {
    ...mapActions({
      'fetchItems': 'realizationsTender/fetchItems',
      'clearItems': 'realizationsTender/clearItems',
    }),
    onUpdate() {
      if (includes([10, 12], toString(this.inn).length)) {
        this.fetchItems({
          inn: this.inn,
          distributor: this.distributor,
          type: 'tender'
        }).catch(() => {
          this.clearItems();
        });
      } else {
        this.clearItems();
      }
    },
  },
  watch: {
    'watchData': {
      immediate: true,
      handler() {
        this.onUpdate();
      }
    },
  },
}
</script>

<style module lang="scss">
.root {
  position: relative;
  :global {
    .v-data-footer{
      justify-content: space-between;
      padding: 0;
      .v-data-footer__select {
        .v-input {
          min-width: 60px;
        }
      }
      .v-data-footer__pagination {
        min-width: 70px;
      }
      .v-select {
        margin-left: 18px !important;
      }
    }
  }
}
.table {
  thead {
    white-space: nowrap;
  }
}
.tr {
  td {}
}
.two {
  td {
    &:not(.v-data-table__mobile-row) {
      width: 50%;
    }
  }
}
.full {
  td {
    &:not(.v-data-table__mobile-row) {
      width: 200px;
      &:first-child{
        width: 1px;
      }
    }
  }
}
.loader {
  background: rgba(255, 255, 255, 0.5);
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
