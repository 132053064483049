import { render, staticRenderFns } from "./ClientListFilter.vue?vue&type=template&id=52464c72&"
import script from "./ClientListFilter.vue?vue&type=script&lang=js&"
export * from "./ClientListFilter.vue?vue&type=script&lang=js&"
import style0 from "./ClientListFilter.vue?vue&type=style&index=0&module=true&lang=scss&"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VSelect,VTextField})
