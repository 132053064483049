import { get, toString } from 'lodash';
import { priceAdapter } from '@/store/utils';

/**
 * Выборка полей продажи из структуры arResult
 * @param item
 * @returns {{date: string, period: string, inn: string, client: string, sum: string, id: string, distributor: {name: string, id: string}}}
 */
export const realizationItemAdapter = (item) => ({
  id: toString(get(item, 'id')),
  inn: toString(get(item, 'inn')),
  sum: priceAdapter(get(item, 'sum', 0)),
  last12month: priceAdapter(get(item, 'last12month', 0)),
  date: toString(get(item, 'date')),
  period: toString(get(item, 'period')),
  client: {
    id: null, // TODO: нужно вернуть из БД
    name: toString(get(item, 'client_name')),
  },
  distributor: {
    id: toString(get(item, 'distributor_id')),
    name: toString(get(item, 'distributor_name')),
  },
});
