import {get, map, toString} from 'lodash';
import {saleAdapter, historyAdapter, clientAdapter, distributorAdapter, priceAdapter} from '@/store/utils';
/**
 * Выборка полей клиента из структуры arResult
 * @param item
 * @returns {{site: string, address: string, clients: unknown[], phone: string, name: string, inn: string, id: string, classification: {code: string, name: string}, status: {code: string, name: string}}}
 */
export const clientItemAdapter = (item) => ({
  id: toString(get(item, 'id')),
  name: toString(get(item, 'name')),
  inn: toString(get(item, 'code')),
  okved: toString(get(item, 'displayProperties.okved.value')),
  classificationOkved: toString(get(item, 'displayProperties.classificationOkved.value')),
  address: toString(get(item, 'displayProperties.address.value')),
  phone: toString(get(item, 'displayProperties.phone.value')),
  site: toString(get(item, 'displayProperties.site.value')),
  region: {
    code: toString(get(item, 'displayProperties.region.value')),
    name: toString(get(item, 'displayProperties.region.displayValue')),
  },
  district: { // Федеральный округ
    code: toString(get(item, 'displayProperties.district.value')),
    name: toString(get(item, 'displayProperties.district.displayValue')),
  },
  status: {
    code: toString(get(item, 'displayProperties.status.value')),
    name: toString(get(item, 'displayProperties.status.displayValue')),
  },
  statusChangeDate: {
    code: toString(get(item, 'displayProperties.statusChangeDate.value')),
    name: toString(get(item, 'displayProperties.statusChangeDate.displayValue')),
  },
  recommendedStatus: {
    code: toString(get(item, 'displayProperties.recommendedStatus.value')),
    name: toString(get(item, 'displayProperties.recommendedStatus.displayValue')),
  },
  classification: {
    code: toString(get(item, 'displayProperties.classification.value')),
    name: toString(get(item, 'displayProperties.classification.displayValue')),
  },
  ckg: {
    code: toString(get(item, 'displayProperties.ckg.value')),
    name: toString(get(item, 'displayProperties.ckg.displayValue')),
  },
  bpConnected: {
    code: toString(get(item, 'displayProperties.bpConnected.valueXmlId')),
    name: toString(get(item, 'displayProperties.bpConnected.displayValue')),
  },
  realizations: toString(priceAdapter(get(item, 'displayProperties.realizations.value', 0))),
  realizationsCislink: toString(priceAdapter(get(item, 'displayProperties.realizationsCislink.value', 0))),
  realizationsTender: toString(priceAdapter(get(item, 'displayProperties.realizationsTender.value', 0))),
  distributors: map(get(item, 'displayProperties.distributors.linkElementValue'), distributorAdapter),
  clients: map(get(item, 'displayProperties.related.linkElementValue'), clientAdapter),
  sales: map(get(item, 'sale'), saleAdapter),
  nextSales: map(get(item, 'nextSale', get(item, 'sale')), saleAdapter),
  advanceDiscountPeriod: toString(get(item, 'displayProperties.advanceDiscountFrom.period')),
  advanceDiscountFrom: toString(get(item, 'displayProperties.advanceDiscountFrom.value')),
  advanceDiscountTo: toString(get(item, 'displayProperties.advanceDiscountTo.value')),
  history: map(get(item, 'history'), historyAdapter),
});
